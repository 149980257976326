<template>
	<v-card class="sacrifice-card">
		<v-card-text v-if="!success">
			<p class="text-center text-title">Autosacrifice Tokens</p>
			<p class="text-center text-small-light mb-6">Double your autosacrifice point balance and recieve them as points</p>
			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">Auto sacrifice balance:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ points }} Points</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">You will have to sacrifice:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ points * 2 }} Points</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-btn elevation="0" class="text-dark mb-6 mt-6"  @click="submit()" rounded block color="systemwhite" :disabled="!connected">SACRIFICE TOKENS</v-btn>
		</v-card-text>
		<v-card-text v-else>
			<p class="text-center text-title">Transaction succesfull</p>
			<p class="text-center text-small-light mb-6">You have successfully autosacrificed your point balance</p>
		
			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">You have recieved:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ points + (points * 2) }} Points</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="4"><span class="text-offwhite text-small-light">Exchange rate:</span></v-col>
				<v-col cols="8"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ data.value }} $MAMOT = {{ points }} Points</p></v-col
				>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
			</v-row>
		
			<v-btn elevation="0" class="text-dark mb-6 mt-6" rounded block color="systemwhite" to="/">Back</v-btn>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	methods: {
		submit() {
			this.success = !this.success;
		}
	},
	data: () => ({
		data: {
			value: ""
		},
		rate: 1,
		success: false
	}),

	computed: {
		points() {
			return (this.data.value * this.rate);
		}
	},
	props: {
		connected: Boolean,
	},

}
</script>